@import "styles/themes.scss";
@import "styles/utils.scss";

.page-intro-card {
  width: 100%;
  height: 308px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  @include themed() {
    background-color: t("color-hover-green");
    box-shadow: t("shadow");
  }

  &-disable-margin {
    margin-bottom: 0 !important;
  }

  &-content {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 60px;
    max-width: 400px;
    width: 50%;
  }

  &-image {
    width: 50%;
    max-width: 550px;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &-collapsed {
    min-height: 60px;
    height: 60px;

    transition: 0.3s ease-out !important;
  }
}
