@import "styles/themes.scss";

.default-filter-container {
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: auto;
  padding: 0;

  &--disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  .filter-button-wrapper {
    display: flex;
    width: auto;
    align-items: center;
    height: 36px;
    border-radius: 6px;
    padding: 4px;
    box-sizing: border-box;

    @include themed() {
      background-color: t("color-grey-40");
    }

    &--disabled {
      button:hover {
        cursor: not-allowed;

        @include themed() {
          background-color: t("color-grey-40") !important;
        }
      }
    }

    button {
      width: auto !important;
      height: 28px !important;
      padding: 7px 8px !important;
      border-radius: 3px !important;
  
      @include themed() {
        background-color: t("color-grey-40");
      }
  
      &:hover {
        @include themed() {
          background-color: t("color-hover-green");
        }
      }
    }

    .icon-button {
      height: 14px !important;
      border-radius: 3px !important;
  
      @include themed() {
        background-color: t("color-grey-40");
      }
  
      &:hover {
        @include themed() {
          background-color: t("color-hover-green");
        }
      }
    }
  }

  .select-dropdown-list-buttons-wrapper {
    border-radius: 6px !important;
  }

  .date-range-filter-wrapper {
    position: relative;

    .date-range-filter-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      height: 36px;
      width: auto;
      padding: 4px;
      border-radius: 6px;
      box-sizing: border-box;

      @include themed() {
        background-color: t("color-grey-40");
      }

      .date-range-label-button, .date-range-label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 28px;
        padding: 8px;
        font-size: $font-size-small-text;
      }

      .date-range-label {
        padding: 0;
        font-weight: $font-weight-light;
      }

      .date-range-close-button {
        margin-right: 6px;
        font-weight: 900;
      }
    }
  }
}
