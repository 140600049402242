@import "styles/themes.scss";
@import "styles/utils.scss";

.organization-sidebar-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 328px;
  height: 100%;
  margin-right: 0px;
  transition: width 0.3s ease;
  padding: 0;

  @include themed() {
    background-color: t("color-grey-40");
  }

  &--collapsed {
    width: 80px;
    align-items: center;

    .sidebar-body {
      overflow: unset;

      .sidebar-content {
        overflow-y: unset;
      }
    }
  }

  @include themed() {
    background-color: t("color-grey-40");
  }

  .sidebar-header {
    &-logo {
      padding: 32px;

      rect {
        fill: #000;
      }

      &--collapsed {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 32px 0;
      }
    }

    &-collapse-icon {
      cursor: pointer;
    }
  }

  .organization-title {
    height: 20px;
    margin: 40px 0;
    padding: 0 32px;
  }

  .user-dropdown {
    position: relative;
    width: 100%;
    position: absolute;
    bottom: 0;


    .dropdown-element {
      border-radius: 0 !important;
      border: none;
      box-shadow: none;
      margin: 0 !important;

      @include themed() {
        background-color: t("color-grey-40") !important;
      }

      .user-info-wrapper {
        p {
          @include themed() {
            color: t("color-text-primary") !important;
          }
        }
      }
    }
  }

  .organization-sidebar-item {
    &:hover {
      .sidebar-item-icon, .sidebar-item-label {
        @include themed() {
          color: t("color-primary");
        }
      }
    }

    .sidebar-item {
      &-icon {
        margin-right: 10px;
      }

      &-icon, &-label {
        @include themed() {
          color: t("color-text-primary");
        }

        @include for-tablet-only {
          display: block !important;
        }
      }
    }
  }
}