// font definition for the font "Roboto"
// when changing the font weight, also change the font-weight variables in themes.scss file
@font-face {
  font-family: "Poppins";
  src: local("Poppins-Light"),
    url("../fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Regular"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Medium"),
    url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Semi-Bold"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Bold"),
    url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 500;
}

// global scss
* {
  font-family: "Poppins" !important;
  font-weight: 400;
  margin: 0;
  padding: 0;

  &:hover {
    transition: all 0.25s ease !important;
  }

  &:focus {
    transition: all 0.25s ease !important;
  }
}
