@import "styles/themes.scss";
@import "styles/utils.scss";

.outlined-date-input-wrapper {
  position: relative;
  display: flex;

  &--single {
    flex-direction: column;
    gap: 0;
  }

  &--range {
    flex-direction: row;
    gap: 10px;
  }

  .outlined-date-input-container {
    min-width: 212px;
    position: relative;
    height: 44px;

    &--single {
      width:100%;
    }
  
    &--range {
      width: 212px;
    }

    .default-input-field {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      font-size: $font-size-running-text;
      font-weight: $font-weight-regular;
      border-radius: 6px;
      padding: 10px 12px 10px 8px;
      transition: 0.1s ease-out;

      @include themed() {
        color: t("color-text-input") !important;
        background: t("color-grey-20") !important;
        border: 1px solid t("color-grey-60");
      }

      &--start-date {
        &--disabled {
          &:hover {
            cursor: not-allowed !important;
          }
        }

        &::-webkit-datetime-edit {
          display: block !important;
          position: absolute;
          bottom: 6px;
          left: 8px;
        }
      }

      &--end-date {
        &--disabled {
          cursor: not-allowed !important;
        }
        
        &::-webkit-datetime-edit {
          display: block !important;
          position: absolute;
          bottom: 6px;
          left: 8px;
          }
      }

      &::-webkit-datetime-edit {
        display: none;
      }

      &::-webkit-calendar-picker-indicator {
        padding-left: calc(100% - 20px);
        padding-right: 0;
        width: 100%;
        height: 20px;

        &:hover {
          cursor: pointer;
        }
      }
  
      &:hover {
        cursor: pointer;
  
        @include themed() {
          border: 1px solid t("color-hover-grey");
        }
      }
  
      &:focus, &--active {
        outline: none;
  
        @include themed() {
          border: 1px solid t("color-text-secondary-black");
          opacity: 0.85;
          box-shadow: none;
        }      
      }
  
      &:focus ~ .default-input-label {
        transform: translateY(50%);
        font-size: 10px;
        left: 9px;
      }

      &--readonly {
        cursor: default !important;

        @include themed() {
          color: t("color-text-primary") !important;
          border-color: t("color-grey-1-inactive") !important;
          background-color: t("color-grey-3-inactive") !important;
        }
      }

      &--disabled {
        cursor: not-allowed !important;
      
        @include themed() {
          color: t("color-text-primary-inactive") !important;
          border-color: t("color-grey-1-inactive") !important;
          background-color: t("color-grey-3-inactive") !important;
        }
      }
    }

    .default-input-label {
      position: absolute;
      font-size: $font-size-running-text;
      font-weight: $font-weight-regular;
      left: 8px;
      transform: translateY(100%);
      transition: 0.1s ease-out;
      transform-origin: left top;
      pointer-events: none;
      opacity: 0.5;
  
      @include themed() {
        color: t("color-text-input") !important;
      }

      &--disabled {
        opacity: 1 !important;

        &:hover {
          cursor: not-allowed !important;
        }

        @include themed() {
          color: t("color-text-inactive") !important;
        }
      }
  
      &--readonly { 
        opacity: 1 !important;

        &:hover {
          cursor: default !important;
        }

        @include themed() {
          color: t("color-text-inactive") !important;
        }
      }
  
      &--on-top {
        transform: translateY(50%);
        font-size: 10px;
        left: 9px;
      }
    }
  }  

  .rdrCalendarWrapper {
    position: absolute;
    z-index: 1;
    width: 212px;
    margin-top: 45px;
    border-radius: 6px;
    padding: 8px 7px;
  
    @include themed() {
      background: t("color-grey-20");
      border: 1px solid t("color-grey-60");
    }
  }
  
  .outlined-date-input-wrapper--single {
    .rdrCalendarWrapper {
      right: 0;
    }
  }
  
  .rdrDateDisplayWrapper{
    display: none !important;
  }
  
  .rdrMonthAndYearWrapper {
    width: 100% !important;
    height: 32px !important;
    padding: 0px !important;
  
    @include themed() {
      border-bottom: 1px solid t("color-grey-60");
    }
  }
  
  .rdrNextPrevButton {
    width: 28px !important;
    height: 28px !important;
    margin: 0 !important;
    padding: 7px !important;
  
    @include themed() {
      background: t("color-grey-20");
    }
  }
  
  .rdrPprevButton i {
    display: inline-block !important;
    padding: 4px !important;
    transform: rotate(135deg) translate(-1px, -1px) !important;
    -webkit-transform: rotate(135deg) translate(-1px, -1px) !important;
  
    @include themed() {
      border: solid t("color-text-secondary-black") !important;
      border-width: 0 1px 1px 0 !important;
    }
  }
  
  .rdrNextButton i {
    display: inline-block !important;
    padding: 4px !important;
    transform: rotate(-45deg) translate(-4px, -4px) !important;
    -webkit-transform: rotate(-45deg) translate(-4px, -4px) !important;
  
    @include themed() {
      border: solid t("color-text-secondary-black") !important;
      border-width: 0 1px 1px 0 !important;
    }
  }
  
  .rdrMonthAndYearPickers {
    display: flex !important;
    justify-content: center !important;
    padding: 0 !important;
    width: 140px !important;
    height: 28px !important;
  }
  
  .rdrMonthAndYearPickers select {  
    height: 28px !important;
    font-size: $font-size-small-text;
    font-weight: $font-weight-bold;
  }
  
  .rdrMonthPicker, .rdrYearPicker {
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .rdrMonthPicker select {
    padding: 0 20px 0 8px !important;
    background-image: url("/assets/icons/chevron_down_alt.svg") !important;
  }
  
  .rdrYearPicker select {
    padding: 0 22px 0 8px !important;
    background-image: url("/assets/icons/chevron_down_alt.svg") !important;
  }
  
  .rdrMonth {
    width: 194px !important;
    box-sizing: border-box;
    padding: 0 !important;
  }
  
  .rdrWeekDays {
    width: 194px !important;
    height: 28px !important;
  }
  
  .rdrDays {
    width: 196px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .rdrDay {
    width: 28px !important;
    height: 28px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .rdrDayHovered .rdrDayNumber:after {
    width: 28px !important;
    height: 28px !important;
    border: none !important;
    border-radius: 3px !important;
    top: -5px !important;
  }
  
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
    width: 28px !important;
    height: 28px !important;
    border: none !important;
    border-radius: 3px !important;
    top: 0 !important;
  
    @include themed() {
      background: t("color-hover-green") !important;
    }
  }
  
  .rdrStartEdge ~ .rdrDayStartPreview.rdrDayEndPreview {
    border-radius: 4px 0 0 4px !important;
  }
  
  .rdrEndEdge ~ .rdrDayStartPreview.rdrDayEndPreview {
    border-radius: 0 4px 4px 0 !important;
  }
  
  .rdrInRange ~ .rdrDayStartPreview.rdrDayEndPreview {
    border-radius: 0 !important;
  }
  
  .rdrDayStartPreview.rdrDayEndPreview ~ .rdrDayNumber span {
    @include themed() {
      color: t("color-text-inactive") !important;
    }
  }
  
  .rdrDayNumber {
    > span {
      z-index: 1 !important;
      font-size: $font-size-small-text !important;
    }
  }
  
  .rdrSelected, .rdrStartEdge, .rdrEndEdge, .rdrInRange {
    width: 28px !important;
    height: 28px !important;
    border: none !important;
    border-radius: 4px !important;
    left: 0 !important;
    top: 0 !important;
  
    @include themed() {
      background: t("color-text-inactive") !important;
    }
  }
  
  .rdrEndEdge {
    border-radius: 0 4px 4px 0 !important;
  }
  
  .rdrDayHovered {
    .rdrEndEdge {
      opacity: 0.85 !important;
  
      @include themed() {
        background: t("color-text-secondary-black") !important;
      }
    }
  }
  
  .rdrStartEdge {
    border-radius: 4px 0 0 4px !important;
  }
  
  .rdrInRange {
    border-radius: 0 !important;
    opacity: 0.85 !important;
  
    @include themed() {
      background: t("color-text-secondary-black") !important;
    }
  }
  
  .rdrStartEdge.rdrEndEdge {
    border-radius: 4px !important;
  }
  
  .rdrStartEdge.rdrEndEdge ~ .rdrDayStartPreview.rdrDayEndPreview  {
    border-radius: 4px !important;
  }
  
  .rdrSelected ~ .rdrDayNumber span {
    @include themed() {
      color: t("color-background") !important;
    }
  }
  
  .rdrDayToday .rdrDayNumber span{
    font-weight: $font-weight-bold !important;
  }
  
  .rdrDayToday .rdrDayNumber span:after{
    display: none;
  }
  
  // styles for the large size DateRange Calendar
  .date-range-large-size.rdrDateRangeWrapper{
    position: absolute !important;
    left: 0 !important;
    width: 434px;
  
    .rdrMonthsHorizontal {
      justify-content: space-between;
    }
  }
}
