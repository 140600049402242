@import "styles/themes.scss";
@import "styles/utils.scss";

.widget-template-container {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  border: 1.5px solid transparent;
  max-width: 165px;

  @include themed() {
    border: 1.5px solid t("color-primary");
  }

  @include for-phone-only {
    width: 100%;
  }

  &-active {
    @include themed() {
      background-color: rgba(t("color-primary"), 0.2);
    }
  }

  &-image {
    height: 100px;
    max-height: 250px;
    width: auto;
    object-fit: contain;
  }

  .description {
    text-align: center;
  }
}
