@import "styles/themes.scss";
@import "styles/utils.scss";

.list-data-table {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-header {
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 100%;
    height: 32px;
    padding: 0 0 0 8px;
    box-sizing: border-box;
    border-radius: 12px 12px 0 0;

    @include themed() {
      background-color: t("color-grey-60");
    }

    &--disabled {
      opacity: 0.6 !important;

      &:hover {
        cursor: not-allowed !important;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:hover {
        cursor: pointer;

        .list-data-table-header-item-icon {
          display: block;
          opacity: 0.5 !important;
        }
      }

      &--disabled {
        &:hover {
          cursor: not-allowed !important;
        }
      }

      &-icon {
        width: 12px;
        height: 12px;
        transform: rotate(360deg);
        transition: 0.2s;
        display: none;
        margin-top: 2px;
        margin-left: 4px;

        &--visible {
          display: block;
        }

        &--desc {
          transform: rotate(180deg);
          transition: 0.2s;
        }
      }
    }
  }

  &-body {
    position: relative;
    height: calc(100% - 32px); // 100% - header's height
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

// Needs to be outside because of the sortable
.list-data-table-body-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  align-items: center;

  &:not(:last-child) {
    @include themed() {
      border-bottom: 1px solid t("color-border");
    }
  }

  @include for-phone-only {
    gap: 10px !important;
  }

  &:hover {
    cursor: pointer;
  }

  &--selected {
    @include themed() {
      background-color: t("color-hover-green") !important;
    }
  }
}

// skeleton

.skeleton-list-data-item-wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 15px 63px 15px 63px;
  box-sizing: border-box;
  box-sizing: border-box;
  gap: 29px;
  align-items: center;

  @include for-phone-only {
    padding: 10px $padding-page-content-left-right-mobile;
  }

  @include themed() {
    &:not(&--active):hover {
      background-color: t("color-hover");
    }
  }

  .skeleton-list-data-item-text-container {
    flex: 2;
    padding-left: 10px;
    text-overflow: ellipsis;
  }
}
