@import "styles/themes.scss";

.default-select-container {
  position: relative;
  width: 100%;
  min-width: 240px;

  &:hover {
    cursor: not-allowed;
  }

  &--readonly {
    &:hover {
      cursor: default !important;
    }
  }

  .default-select-label {
    position: absolute;
    font-size: $font-size-running-text;
    font-weight: $font-weight-regular;
    left: 8px;
    top: 0;
    transform: translateY(100%);
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    opacity: 0.5;

    @include themed() {
      color: t("color-text-primary");
    }

    &--disabled {
      opacity: 1 !important;

      &:hover {
        cursor: not-allowed !important;
      }

      @include themed() {
        color: t("color-text-inactive");
      }
    }

    &--readonly {
      opacity: 1 !important;

      &:hover {
        cursor: default !important;
      }

      @include themed() {
        color: t("color-text-inactive");
      }
    }

    &--on-top {
      transform: translateY(50%);
      font-size: 10px;
      left: 9px;
    }

    &--on-top-wrap {
      transform: translateY(30%);
      font-size: 10px;
      left: 9px;
    }

    &--error {
      @include themed() {
        color: t("color-accent-red");
        opacity: 1;
      }
    }

    .default-required-field {
      opacity: 1 !important;

      @include themed() {
        color: t("color-brand-primary");
      }

      &--error {
        @include themed() {
          color: t("color-accent-red");
          opacity: 1;
        }
      }

      &--disabled {
        opacity: 1 !important;
  
        @include themed() {
          color: t("color-brand-green-inactive") !important;
        }
      }

      &--readonly {
        opacity: 1 !important;
  
        @include themed() {
          color: t("color-brand-green-inactive") !important;
        }
      }
    }
  }

  .default-count-field {
    position: absolute;
    right: 30px;
    top: 13px;
    display: flex;
    align-items: center;
    width: 32px;
    height: 18px;
    font-size: 10px;
    font-family: "Poppins";

    span {
      text-align: center;
      display: inline-block;
      width: 14px;
      height: 18px;
      line-height: 18px;
      border-radius: 4px 0 0 4px;

      @include themed() {
        color: t("color-text-input") !important;
        background-color: t("color-grey-40");
      }
    }

    button {
      cursor: pointer;
      width: 18px;
      height: 18px;
      background-image: url("/assets/icons/close.svg");
      background-repeat: no-repeat;
      border: 3px solid transparent;
      border-radius: 0 4px 4px 0;

      @include themed() {
        background-color: t("color-grey-40");
      }

      &:hover {
        background-image: url("/assets/icons/close-error.svg");

        @include themed() {
          background-color: t("color-accent-red-20");
        }
      }
    }
  }

  .default-image-field {
    width: auto;
    max-width: 52px;
    height: 20px;
    box-sizing: border-box;
    position: absolute;
    right: 30px;
    top: 12px;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-family: "Poppins";

    &--image {
      position: absolute;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @include themed() {
        border: 2px solid t("color-grey-40");
        background-color: t("color-grey-80");
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: $main-border-radius;
      z-index: 9;
      outline: 2px;

      @include themed() {
        background-color: t("color-grey-40");
      }
    }
  }
}

// react-select component styles
.default-select-dropdown {
  &__control {
    width: 100% !important;
    height: auto !important;
    min-height: 44px !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    font-size: $font-size-running-text !important;
    font-weight: $font-weight-regular !important;

    @include themed() {
      color: t("color-text-input") !important;
      background: t("color-grey-20") !important;
      border: 1px solid t("color-grey-60") !important;
    }

    &:hover {
      cursor: pointer;

      @include themed() {
        border: 1px solid t("color-hover-grey") !important;
      }
    }

    &--is-focused {
      @include themed() {
        border: 1px solid t("color-hover-grey") !important;
      }
    }
  }

  &__indicator-separator {
    display: none !important;
  }

  &__clear-indicator {
    display: none !important;
  }

  &__dropdown-indicator {
    @include themed() {
      color: t("color-text-input") !important;
    }

    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }

  &__value-container {
    padding-left: 6px !important;
    bottom: -8px !important;

    &--is-multi {
      width: auto !important;
      padding-left: 4px !important;
      margin: 10px 0 !important;
    }
  }

  &__single-value {
    @include themed() {
      color: t("color-text") !important;
    }
  }

  &__multi-value {
    height: 18px !important;
    border: none !important;
    border-radius: 4px !important;
    align-items: center !important;
    font-size: 10px !important;
    font-family: "Poppins" !important;
    font-weight: $font-weight-regular !important;

    @include themed() {
      background: t("color-grey-40") !important;
    }

    &__label {
      padding: 0 8px !important;

      @include themed() {
        color: t("color-text-input") !important;
      }

      .defaul-option-field {
        img {
          display: none;
        }

        span {
          font-size: 10px !important;
        }

        &--meta-data {
          margin: 0;

          span:last-child {
            display: none !important;
          }
        }
      }
    }

    &__remove {
      width: 18px;
      height: 18px;
      padding: 0 !important;
      background-image: url("/assets/icons/close.svg");
      background-repeat: no-repeat !important;
      border: 3px solid transparent !important;

      &:hover {
        border-radius: 0 4px 4px 0 !important;
        background-image: url("/assets/icons/close-error.svg");

        @include themed() {
          background-color: t("color-accent-red-20");
        }
      }

      svg {
        display: none !important;
      }
    }
  }

  &__menu {
    width: 100% !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    padding: 8px !important;
    margin: 0 !important;

    @include themed() {
      background-color: t("color-background") !important;
      border: 1px solid t("color-grey-40") !important;
      box-shadow: 3px 6px 20px 0px rgba(0, 0, 0, 0.08);
    }
  }

  &__menu-list {
    padding: 0 !important;
  }

  &__option {
    cursor: pointer !important;
    width: 100% !important;
    height: auto !important;
    min-height: 32px !important;
    line-height: 130% !important;
    border-radius: 3px !important;
    padding: 8px !important;
    margin-bottom: 4px !important;
    font-family: "Poppins" !important;
    font-size: $font-size-running-text !important;
    font-weight: $font-weight-regular !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    @include themed() {
      color: t("color-text") !important;
    }

    &--is-focused {
      @include themed() {
        background-color: t("color-background") !important;
      }
    }

    &:hover,
    &:focus,
    &--is-selected {
      border-radius: 3px !important;

      @include themed() {
        background-color: t("color-grey-40") !important;
      }
    }

    &--is-selected {
      position: relative;

      .defaul-option-field--meta-data {
        ::after {
          display: none !important;
        }
      }
    }

    &--is-selected::after {
      position: absolute;
      right: 8px;
      top: 10px;
      content: "";
      background-image: url("/assets/icons/check.svg");
      width: 14px;
      height: 14px;
    }

    &--is-selected:has(> .defaul-option-field--meta-data)::after {
      position: absolute;
      left: 8px;
      top: 10px;
      content: "";
      background-image: url("/assets/icons/check.svg");
      width: 14px;
      height: 14px;
    }
  }

  &__input-container {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__value-container {
    padding: 0 0 0 7px !important;
  }
}

// dropdown icon rotation when open/close menu
.default-select-dropdown__control {
  .default-select-dropdown__indicators {
    .default-select-dropdown__dropdown-indicator {
      transform: rotate(0deg) !important;
      transition: 0.2s !important;
    }
  }

  &--menu-is-open {
    .default-select-dropdown__indicators {
      .default-select-dropdown__dropdown-indicator {
        transform: rotate(180deg) !important;
        transition: 0.2s !important;
      }
    }
  }
}

.css-t3ipsp-control {
  box-shadow: none !important;
}

.react-select-container {
  // error validation message select container border style
  &--error,
  &--error:hover {
    .default-select-dropdown__control {
      @include themed() {
        border-color: t("color-accent-red") !important;
      }
    }
  }

  &--disabled {
    .default-select-dropdown__control {
      @include themed() {
        color: t("color-text-primary-inactive") !important;
        border-color: t("color-grey-1-inactive") !important;
        background-color: t("color-grey-3-inactive") !important;
      }

      .default-select-dropdown__indicators {
        .default-select-dropdown__dropdown-indicator {
          visibility: hidden !important;
        }
      }
    }  
  }

  &--readonly {
    .default-select-dropdown__control {
      @include themed() {
        border-color: t("color-grey-1-inactive") !important;
        background-color: t("color-grey-3-inactive") !important;
      }

      &:hover, &:focus {
        opacity: 100% !important;
  
        @include themed() {
          border-color: t("color-grey-60") !important;
        }
      }
      
      .default-select-dropdown__indicators {
        .default-select-dropdown__dropdown-indicator {
          visibility: hidden !important;
        }
      }
    }
  }  
}

.defaul-option-field {
  display: flex;
  align-items: center;
  height: 16px !important;

  span {
    font-family: "Poppins" !important;
    font-size: $font-size-running-text !important;
    font-weight: $font-weight-light !important;
  }

  &--disabled {
    @include themed() {
      color: t("color-text-primary-inactive") !important;
    }
  }

  &--readonly {
    @include themed() {
      color: t("color-text-primary") !important;
    }
  }

  &--image {
    img,
    div {
      width: 20px;
      height: 20px;
      border-radius: 10px !important;
      box-shadow: none !important;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 6px;
    }
  }

  &--icon {
    img,
    div {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }

  &--meta-data {
    position: relative;
    margin-left: 20px;

    span:last-child {
      position: absolute;
      display: inline-block;
      height: 16px;
      width: auto;
      max-width: 50px;
      top: 0;
      right: 8px;
      font-family: "Poppins";
      font-size: $font-size-running-text;
      font-weight: $font-weight-light;
      overflow: hidden;

      @include themed() {
        color: t("color-text-inactive");
      }
    }
  }
}
