@import "styles/themes.scss";

.image-upload-container {
  height: 300px;
  width: 300px;
  border-radius: $main-border-radius;
  padding: 5px;
  box-sizing: border-box;
  aspect-ratio: 1;
  cursor: pointer;

  @include themed() {
    border: dashed 2px t("color-border");
  }

  .hidden-input {
    visibility: hidden;
  }

  &--error {
    @include themed() {
      border: dashed 2px t("color-error");
    }
  }

  .uploaded-file-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: calc($main-border-radius - 1px);
  }
}
