@import "styles/themes.scss";

.asset-modal-wrapper {
  height: 74vh;

  .form-wrapper {
    padding: 0 30px;
  }

  .container-wrapper {
    height: 52vh;
    
    .content-container {
      margin: 0;

      .template-image-container {
        position: relative;
        height: 190px;
        width: 190px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 20px;
        overflow: hidden;
        cursor: pointer;
        border: 2px solid transparent;
        
        .template-image {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      
        &--selected {
          @include themed() {
            border: 2px solid t("color-primary");
          }
      
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
      
            @include themed() {
              background-color: rgba(t("color-primary"), 0.3);
            }
          }
        }
      
        .templait-item-icon {
          height: 20px;
          width: 20px;
          padding: 4px;
          position: absolute;
          top: 8px;
          right: 8px;
          border-radius: 50%;

          &--close {
            @include themed() {
              background-color: t("color-disabled");
            }
          }

          &--check {
            @include themed() {
              color: t("color-surface");
              background-color: t("color-primary");
            }
          }
        }

        .templait-item-index {
          height: 28px;
          width: 28px;
          padding: 0;
          position: absolute;
          top: 8px;
          right: 8px;
          border-radius: 50%;

          @include themed() {
            color: t("color-surface");
            background-color: t("color-primary");
          }
        }
      }
    }

    .upload-container {
      width: 100%;
      height: 50vh;
      padding: 0;
      box-sizing: border-box;
      aspect-ratio: 1;
    
      .file-image {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }    
        
    .button-tags-bar {
      margin-bottom: 10px;
      
      .tag-btn {
        width: 110px;
        height: 35px;
        font-size: 13px;
        margin-right: 20px;
        border-radius: 8px;

        @include themed() {
          color: t("color-text");
          border: 2px solid t("color-border");
          background-color: t("color-background");
        }

        &:not(&--selected):hover {
          @include themed() {
            background-color: t("color-hover");
          }
        }

        &--selected {
          @include themed() {
            border: 2px solid t("color-primary");
            background-color: rgba(t("color-success"), 0.3);
          }
        }
      }
    }
  }

  .asset-modal-add-button {
    position: absolute;
    right: 0;
    width: 110px;
  }
}
