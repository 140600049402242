@import "styles/themes.scss";
@import "styles/utils.scss";

.training-plan-exercise-item {
  padding: 15px 0;

  cursor: pointer;

  &-header {
    cursor: pointer;
  }

  &-content {
    border-radius: 8px;
    overflow: hidden;

    @include themed() {
      background-color: t("color-form-wrapper");
    }

    &-main {
      padding: 20px;
    }
  }

  &-opened {
    padding: 15px 0;

    @include themed() {
      // background-color: t("color-surface-dark");
    }
  }
}

.x-mark-icon {
  margin-left: 13px !important;
  margin-right: 10px !important;
}

.trash-icon {
  margin-left: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: auto;
}

.exercise-index-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.training-type-toggle {
  width: 100%;

  @include themed() {
    border-bottom: 1px solid t("color-border");
  }

  &-item {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    &--selected {
      @include themed() {
        background-color: t("color-border");
      }
    }
  }
}
