@import "styles/themes.scss";

.file-upload-container {
  min-height: 25vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $main-border-radius;
  overflow: hidden;
  cursor: pointer;
  padding: 5px;
  box-sizing: border-box;

  &:hover {
    @include themed() {
      border: dashed 2px t("color-primary");
    }
  }

  @include themed() {
    border: dashed 2px t("color-border");
  }

  &--dragging {
    @include themed() {
      border: dashed 2px t("color-primary");
    }
  }

  &--error {
    @include themed() {
      border: dashed 2px t("color-error");
    }
  }

  &--list {
    height: 30%;
    margin-bottom: 10px;
  }

  .file-upload-list-placeholder {
    &-icon {
      height: 40px;
    }

    &-text {
      font-size: $font-size-title-text;
      width: 30%;
      text-align: center;
    }

    &-bold {
      font-weight: $font-weight-bold;
    }
  }

  .file-upload-placeholder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-icon {
      font-size: 50px;

      @include themed() {
        color: t("color-border");
      }
    }

    &-text {
      @include themed() {
        color: t("color-border");
      }
    }
  }

  .file-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: calc($main-border-radius - 1px);
  }  
}

.validation-message {
  height: 10px;

  @include themed() {
    color: t("color-error");
  }
}

.content-container {
  padding: 0;

  .uploaded-image-container {
    min-height: 70px;
    width: 96%;
    padding: 0 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    
    @include themed() {
      border-bottom: 2px solid t("color-border");
    }

    .uploaded-image-title {
      width: 30%;
    }

    .uploaded-image-icons {
      width: 30%;
    }
  
    .uploaded-image {
      height: 60px;
      width: 60px;
      object-fit: cover;
      border-radius: 5px;
      margin-right: 20px;
    }
  
    &--selected {
      @include themed() {
        border: 2px solid t("color-primary");
        background-color: rgba(t("color-primary"), 0.5);
        border-radius: 5px;
      }
    }
  
    .uploaded-item-icon {
      height: 26px;
      width: 26px;
      margin-left: 20px;
    }

    .uploaded-check-icon {
      height: 12px;
      width: 12px;
      padding: 5px;
      border-radius: 50%;
      margin-right: 10px;

      @include themed() {
        color: t("color-surface");
        background-color: t("color-primary");
      }
    }
  }
}

.upload-asset-list {
  font-size: $font-size-running-text;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
}
