@import "styles/themes.scss";

.job-card-container {
  width: 100%;
  border-radius: $main-border-radius;
  padding: 10px 15px;

  @include themed() {
    background-color: t("color-background");
    // border: 1px solid t("color-border-secondary");
    box-shadow: t("shadow");
  }

  .job-card-info {
    .job-card-name {
      font-size: 15px;
      font-weight: 600;
    }

    .star-icon {
      fill: #d9d9d9;

      &.filled {
        @include themed() {
          fill: t("color-primary");
        }

        path {
          @include themed() {
            fill: t("color-primary");
            opacity: 1;
          }
        }
      }
    }

    .job-card-date {
      font-size: 10px;
      font-weight: 300;
    }
  }

  .job-card-image-wrapper {
    width: fit-content;

    .job-card-image-container {
      width: 36px;
      height: 36px;
      border-radius: 100px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .job-card-image-replace-container {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      font-size: 15px;
      font-weight: 600;
      opacity: 0.8;

      @include themed() {
        background-color: t("color-primary");
      }
    }
  }

  .job-card-new-indicator {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;

    @include themed() {
      background-color: t("color-primary");
      border-radius: $main-border-radius;
    }
  }
}
