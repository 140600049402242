@import "styles/themes.scss";

.toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}
.toggle:before {
  content: "";
  position: relative;
  top: 0;
  left: 1px;
  width: 40px;
  height: 20px;
  display: block;
  border-radius: 12px;
  transition: background 0.2s ease;

  @include themed() {
    background: t("color-grey-80");
  }
}
.toggle span {
  position: absolute;
  top: 1px;
  left: 2px;
  width: 18px;
  height: 18px;
  display: block;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;

  @include themed() {
    background: t("color-surface");
  }
}
.toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  
  @include themed() {
    background: t("color-primary");
  }
  
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

#cbx:checked + .toggle:before {
  @include themed() {
    background: t("color-primary");
  }
}

#cbx:checked + .toggle span {
  @include themed() {
    background: t("color-surface");
  }

  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}

.center {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 20px);
}

.toggle-switch-wrapper {
  cursor: pointer;
  width: fit-content;
  user-select: none;

  &--disabled {
    cursor: default;

    .toggle {
      cursor: default;
    }
  }
}