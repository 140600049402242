@import "styles/themes.scss";

.library-image {
  max-height: 400px;
}

.installed-indicator {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 10px;
}
