@import "styles/themes.scss";

.task-board-container {
  position: relative;
  min-height: 90%;
  width: 100%;
  margin-top: 10px;
  overflow: hidden;

  .board-column-header {
    @include themed() {
      font-size: $font-size-small-headline;
    }
  
    &-counter {
      width: 30px;
      height: 30px;
      border-radius: 50%;
  
      @include themed() {
        background-color: t("color-border");
      }
    }
  
    &-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
  
      @include themed() {
        &:hover {
          cursor: pointer;
          background-color: t("color-hover");
        }
      }
    }
  }
  
  .task-card-container {
    height: 95px;
    width: 100%;
    border-radius: $main-border-radius;
    padding: 10px 15px;
  
    @include themed() {
      background-color: t("color-background");
      box-shadow: t("shadow");
    }
  
    .task-card-info {
      height: 100%;
      font-size: $font-size-running-text;
      font-weight: $font-weight-regular;
  
      .task-card-title {
        font-size: $font-size-title-text;
        font-weight: $font-weight-bold;
      }
  
      .task-card-date {
        font-size: 10px;
      }

      .task-card-priority {
        height: 10px;
      }

      .task-card-image {
        width: 25px;
        height: 25px;
        border-radius: 5px;
      }
    }  
  }

  .react-kanban-board {
    padding: 0;
  
    .react-kanban-column {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
