@import "styles/themes.scss";

.permission-group-page-container {
  @include themed() {
    background-color: t("color-grey-20");
  }
  
  .permission-group-page-header {
    padding-bottom: 20px;

    @include themed() {
      border-bottom: 1px solid t("color-border");
    }
  }

  .permission-group-page-list {
    position: relative;
    height: 100%;
    width: 60%;
    min-width: 720px;
    margin: 40px 0;
    padding: 20px;
    border-radius: 14px;
    overflow: hidden;

    @include themed() {
      background-color: t("color-surface");
    }

    .group-list-header {
      padding-bottom: 10px;

      @include themed() {
        border-bottom: 1px solid t("color-border");
      }
    }

    .group-list-content {
      overflow: auto;
      
      .permission-group-title, .permission-group-item {
        height: 36px;
        padding: 12px 8px;
      }

      .permission-group-title {
        border-radius: 8px;

        @include themed() {
          background-color: t("color-grey-40");
        }

        p {
          font-weight: $font-weight-semi-bold !important;
        }
      }

      .permission-group-item {
        p {
          font-weight: $font-weight-regular !important;
        }
      }
    }
  }
}