@import "styles/themes.scss";

.link-button-wrapper {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &--icon-left {
    flex-direction: row-reverse;
  }

  &--fit-content {
    width: fit-content !important;
  }

  &--no-padding {
    padding: 0;
  }

  .btn-icon {
    &--left {
      margin-right: 10px;
    }

    &--right {
      margin-left: 10px;
    }
  }

  &:not(&--disabled):hover {
    .link-button {
      @include themed() {
        color: t("color-primary-highlight");
      }
    }

    .btn-icon {
      @include themed() {
        color: t("color-primary-highlight");
      }
    }
  }

  &--disabled {
    pointer-events: none !important;

    .link-button {
      @include themed() {
        color: grey;
      }
    }

    .btn-icon {
      @include themed() {
        color: grey;
      }
    }
  }
}

.link-button {
  border: none;
  cursor: pointer;
  font-weight: $font-weight-bold;
  color: #fff;
  background-color: transparent;
  @include themed() {
    color: t("color-primary");
  }

  // &:not(&--disabled):hover {
  //   @include themed() {
  //     color: t("color-primary-highlight");
  //   }
  // }

  // &--disabled {
  //   cursor: not-allowed;
  //   @include themed() {
  //     color: grey;
  //   }
  // }

  &--danger {
    @include themed() {
      color: t("color-error");
    }

    &:not(&--disabled):hover {
      @include themed() {
        color: rgba(t("color-error"), 0.7);
      }
    }
  }

  &--secondary {
    @include themed() {
      color: t("color-secondary");
    }

    &:not(&--disabled):hover {
      @include themed() {
        color: t("color-secondary-highlight");
      }
    }
  }

  &--warning {
    @include themed() {
      color: t("color-warning");
    }

    &:not(&--disabled):hover {
      @include themed() {
        color: rgba(t("color-warning"), 0.7);
      }
    }
  }

  &--running-text {
    font-size: $font-size-running-text;
  }

  &--small-text {
    font-size: $font-size-small-text;
  }

  &--headline {
    font-size: $font-size-default-headline;
  }
}
