@import "styles/themes.scss";
@import "styles/utils.scss";

.unit-input-container {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 240px;
  height: 44px !important;
  box-sizing: border-box;
  border-radius: 6px 4px 4px 6px;

  @include themed() {
    border: 1px solid t("color-grey-60");
  }

  &:hover {
    @include themed() {
      border: 1px solid t("color-hover-grey");
    }
  }

  &--error, &--error:hover, &--error:focus {
    @include themed() {
      border-color: t("color-accent-red");
    }
  }

  &--disabled {
    @include themed() {
      color: t("color-text-primary") !important;
      border-color: t("color-grey-1-inactive") !important;
      background-color: t("color-grey-3-inactive") !important;
    }

    &:hover, &:focus {
      cursor: not-allowed;
      @include themed() {
        border: 1px solid t("color-grey-60");
      }
    }
  }

  &--readonly {
    @include themed() {
      color: t("color-text-primary") !important;
      border-color: t("color-grey-1-inactive") !important;
      background-color: t("color-grey-3-inactive") !important;
    }

    &:hover, &:focus {
      @include themed() {
        border-color: t("color-grey-60") !important;
      }
    }
  }

  .unit-input-field {
    position: relative;
    width: calc(100% - 45px) !important;
    height: 100% !important;
    font-family: "Poppins" !important;
    font-size: $font-size-running-text !important;
    font-weight: $font-weight-light !important;
    padding: 18px 8px 2px !important;
    box-sizing: border-box !important;
    border: transparent !important;
    border-radius: 6px 0 0 6px !important;
    transition: 0.1s ease-out;

    @include themed() {
      color: t("color-text-input");
      background: t("color-grey-20");
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;

      @include themed() {
        border: 1px solid t("color-text-secondary-black");
        opacity: 0.85;
        box-shadow: none;
      }      
    }

    &:focus ~ .unit-input-label {
      transform: translateY(50%);
      font-size: 10px;
      left: 9px;
    }

    &--disabled {
      cursor: not-allowed !important;
      opacity: 40% !important;
    }

    &--readonly {
      &:hover, &:focus {
        cursor: default !important;
      }
    }
  }

  .unit-input-label {
    position: absolute;
    font-size: $font-size-running-text;
    font-weight: $font-weight-regular;
    left: 8px;
    transform: translateY(100%);
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    opacity: 0.5;

    @include themed() {
      color: t("color-text-primary");
    }

    &--disabled {
      opacity: 1 !important;

      &:hover {
        cursor: not-allowed !important;
      }

      @include themed() {
        color: t("color-text-inactive");
      }
    }

    &--readonly {
      opacity: 1 !important;

      &:hover {
        cursor: default !important;
      }

      @include themed() {
        color: t("color-text-inactive");
      }
    }

    &--on-top {
      transform: translateY(50%);
      font-size: 10px;
      left: 9px;
    }

    &--error {
      @include themed() {
        color: t("color-accent-red");
        opacity: 1;
      }
    }

    .required-field {
      opacity: 1 !important;

      @include themed() {
        color: t("color-brand-primary") !important;
      }

      &--error {
        @include themed() {
          color: t("color-accent-red");
          opacity: 1;
        }
      }

      &--disabled {
        opacity: 1 !important;
  
        @include themed() {
          color: t("color-brand-green-inactive") !important;
        }
      }

      &--readonly {
        opacity: 1 !important;
  
        @include themed() {
          color: t("color-brand-green-inactive") !important;
        }
      }
    }
  }

  .unit-select-dropdown {
    &__control {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      width: 64px !important;
      height: 100% !important;
      border-radius: 0 4px 4px 0 !important;
      border: none !important;
      padding-left: 8px;
      padding-right: 6px;
      font-family: "Poppins" !important;
      font-size: $font-size-running-text !important;
      font-weight: $font-weight-regular !important;
  
      @include themed() {
        color: t("color-text-input") !important;
        background: t("color-grey-40") !important;
      }
  
      &:hover {
        cursor: pointer;

        @include themed() {
          background: t("color-grey-60") !important;
        }
      }
  
      &--is-focused {
        box-shadow: none !important;
      }

      &--menu-is-open {
        
        @include themed() {
          background: t("color-hover-green") !important;
        }
      }
    }

    &__value-container {
      padding: 0;
    }

    &__input-container {
      padding: 0;
      margin: 0;
      width: 34px !important;;
    }
  
    &__indicator-separator {
      display: none !important;
    }
  
    &__clear-indicator {
      display: none !important;
    }

    &__indicator {
      width: 12px !important;
      height: 12px !important;
      background-image: url("/assets/icons/chevron_down.svg");
      background-repeat: no-repeat;
      padding: 0 !important;

      @include themed() {
        color: t("color-text-input") !important;
      }
  
      svg {
        display: none !important  ;
        width: 12px !important;
        height: 12px !important;
      }
    }
  }

  // dropdown icon rotation when open/close menu
  .unit-select-dropdown__control--menu-is-open {
    .unit-select-dropdown__indicators {
      .unit-select-dropdown__dropdown-indicator {
        transform: rotate(180deg) !important;
      }
    }
  }

}

.unit-select-dropdown__menu {
  margin-top: 0 !important;
  width: 92px !important;
  padding: 8px !important;
  box-shadow: 3px 6px 20px 0px rgba(0, 0, 0, 0.08);
}

.unit-select-dropdown__option {
  width: 100% !important;
  height: 32px !important;
  padding: 8px !important;
  border-radius: 3px !important;
  margin-bottom: 4px !important;
  font-family: "Poppins" !important;
  font-size: $font-size-running-text !important;
  font-weight: $font-weight-light !important;
  line-height: 130% !important;

  @include themed() {
    background: t("color-background") !important;
    color: t("color-text-input") !important;
  }

  &:hover, &--is-selected {
    cursor: pointer;
    
    @include themed() {
      background: t("color-grey-40") !important;
    }
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.unit-select-container {
  &--disabled {
    .unit-select-dropdown__control {
      .unit-select-dropdown__indicators {
        .unit-select-dropdown__dropdown-indicator {
          visibility: hidden !important;
        }
      }
    }  
  }

  &--readonly {
    .unit-select-dropdown__control {
      cursor: default !important;
      opacity: 100% !important;

      &:hover, &:focus {
        cursor: default !important;
        opacity: 100% !important;
  
        @include themed() {
          background-color: t("color-grey-40") !important;
        }
      }
      
      .unit-select-dropdown__indicators {
        .unit-select-dropdown__dropdown-indicator {
          visibility: hidden !important;
        }
      }
    }

    .unit-select-dropdown__single-value {
      @include themed() {
        color: t("color-text-input");
      }
    }
  }  
}
