@import "styles/themes.scss";

.attachment-title {
  width: 160px;
  height: 32px;
  font-size: $font-size-title-text;
}

.attachment-image {
  width: 30px !important;
  height: 30px !important;
}

.uploaded-item-icon {
  height: 20px;
  width: 20px;
  margin-left: 20px;
}

.uploaded-check-icon {
  height: 6px;
  width: 6px;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;

  @include themed() {
    color: t("color-surface");
    background-color: t("color-primary");
  }
}