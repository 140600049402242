@import "styles/themes.scss";

.sidebar-item {
  height: 56px;
  padding: 16px 32px;
  cursor: pointer;

  @include themed() {
    border-bottom: 1px solid t("color-grey-60");
  }

  &-icon {
    height: 18px;
    width: 18px;
  }

  &-label {
    font-size: $font-size-running-text;
    font-weight: $font-weight-medium;
  }

  &-arrow {
    font-size: $font-size-running-text;
  }

  &-icon, &-label, &-arrow {
    @include themed() {
      color: t("color-text-primary");
    }
  }

  &--active,
  &:hover {
    .sidebar-item-icon, .sidebar-item-label, .sidebar-item-arrow {
      @include themed() {
        color: t("color-primary");
      }
    }
  }
}

.sidebar-submenu {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-out,
    max-height 0.3s ease-out;
  max-height: 0;
  padding-top: 8px;
  padding-left: 23px;
  margin-left: 15px;
  position: relative;
  overflow: hidden;
  visibility: hidden;

  &--open {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
    max-height: 500px;
  }

  &--collapsed {
    position: fixed;
    top: 0;
    margin-left: 0;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1 !important;
    transform: scaleY(1) !important;
    visibility: visible !important;
    padding: 10px;
    border-radius: $main-border-radius;
    z-index: 10;
    min-width: 200px;
    width: auto;
  }
}
