@import "styles/themes.scss";

.attachment-item {
  position: relative;
  min-width: 120px;
  max-width: 120px;
  height: 37px;
  overflow: hidden;
  padding: 5px;
  font-size: $font-size-small-text;

  @include themed() {
    background-color: t("color-surface");
    border-radius: 6px;
    border: solid 1px t("color-border");
    box-shadow: t("shadow");
  }
}

.attachment-image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 5px;
  box-shadow: none !important;
}

.attachment-text {
  height: $font-size-title-text;
  width: 70px;
  overflow: hidden;
}

.attachment-icon {
  position: absolute;
  right: 5px;
  width: 16px;
  height: 16px;

  @include themed() {
    color: t("color-error");
  }
}