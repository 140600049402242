@import "styles/themes.scss";
@import "styles/utils.scss";

.advanced-list-data-table {
  height: 100%;
  margin-top: 24px;

  &-header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .table-header-search-section {
      flex-grow: 2;
      flex-basis: 130%;
    }

    .table-header-select-section {
      flex-grow: 1;
    }

    button {
      width: auto;
      height: 36px;
      padding: 10px 12px !important;
      border-radius: 6px !important;
      font-size: $font-size-small-text !important;
      font-weight: $font-weight-medium !important;
      font-family: "Poppins" !important;
    }

    .advanced-select, .advanced-cancel {
      @include themed() {
        background: t("color-grey-40") !important;
      }

      &:hover {
        @include themed() {
          background: t("color-hover-green") !important;
        }
      }
    }

    .advanced-delete {
      opacity: 1;

      @include themed() {
        background-color: t("color-alarm-red") !important;
      }

      &:hover {
        opacity: 0.8;  
      }

      &:disabled {
        opacity: 0.4;

        @include themed() {
          background-color: t("color-accent-red") !important;
        }
      }
    }
  }
}