@import "styles/themes.scss";
@import "styles/utils.scss";
.simple-dropdown-wrapper {
  position: relative;
  width: fit-content;

  .simple-dropdown-button {
    border-radius: $main-border-radius;
    padding: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover:not(.simple-dropdown-button-is-open) {
      @include themed() {
        background-color: t("color-surface");
      }
    }

    &-is-open {
      @include themed() {
        background-color: t("color-surface");
      }
    }

    &--secondary {
      &:hover:not(.simple-dropdown-button-is-open) {
        @include themed() {
          background-color: t("color-secondary-light");
        }
      }
  
      &-is-open {
        @include themed() {
          background: t("color-secondary-light");
        }
      }
    }
  }

  .simple-dropdown-menu-wrapper {
    z-index: 100 !important;
    position: absolute;

    padding: 10px;
    border-radius: $main-border-radius;

    @include themed() {
      box-shadow: t("shadow");
      background: t("color-background");
    }

    &.left-sided {
      right: 0;
    }

    &.right-sided {
      left: 0;
    }

    .simple-dropdown-menu {
      list-style: none;
      z-index: 1000 !important;

      .simple-dropdown-item {
        z-index: 1000 !important;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        padding: 10px;
        margin: 2px 0;
        transition: background-color 0.2s linear;
        border-radius: $main-border-radius;
        min-width: 180px;

        @include themed() {
          background-color: t("color-background");
        }

        &:hover {
          @include themed() {
            background-color: t("color-primary-ultralight");
          }
        }

        &--secondary {
          &:hover {
            @include themed() {
              background-color: t("color-light");
            }
          }
        }
      }
    }
  }
}
