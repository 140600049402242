@import "styles/themes.scss";
@import "styles/utils.scss";

.page-container-body-content-wrapper {
  box-sizing: border-box;
  padding: $page-container-padding;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include for-phone-only {
    padding: $padding-page-content-left-right-mobile;
  }

  &--color-surface {
    @include themed() {
      background-color: t("color-surface");
    }
  }

  &--color-background {
    @include themed() {
      background-color: t("color-background");
    }
  }
}
