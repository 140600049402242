.pageIntroContainer {
  border-radius: 20px;
  padding: 80px 60px;
  background-size: cover;
  display: flex;

  .pageIntroTextContent {
    flex: 1;

    .pageIntroTitle {
      font-weight: 600;
      font-size: 21px;
      margin-bottom: 10px;
    }

    .pageIntroDescription {
      font-weight: 300;
      font-size: 14px;
      width: 50%;
      margin-bottom: 20px;
    }

    .pageIntroButton {
      border-radius: 10px;
      padding: 10px 45px;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;

      background-color: "#f5f5f5";
      border-color: "#f5f5f5";
      color: "#000";
    }
  }

  .pageIntroImageContent {
    flex: 1;

    .pageIntroImage {
      width: 100%;
      height: 150px;
      object-fit: contain;
    }
  }
}
