@import "styles/themes.scss";

.task-tabs-bar {
  position: relative;
  
  @include themed() {
    border-bottom: 1px solid t("color-border");
  }
}

.task-list-type-select {
  position: absolute;
  width: 120px;
  padding-bottom: 0;
}
