@import "styles/themes.scss";

.tab-component {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-size: $font-size-running-text;
  font-weight: $font-weight-bold;
  padding: 4px 2px 2px 2px;

  @include themed() {
    color: t("color-text");
    background-color: transparent;
    border: none;
    border-bottom: 1.5px solid transparent;
  }

  // not active tab and not disabled tab hover
  &:not(.active-tab-component):not(.tab-component--disabled):hover {
    @include themed() {
      color: t("color-primary");
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.active-tab-component {
  @include themed() {
    border-bottom: 1.5px solid t("color-primary");
    color: t("color-primary");
  }
}
