@import "styles/themes.scss";

.title-text {
  font-size: $font-size-title-text;
  font-weight: $font-weight-semi-bold;

  @include themed() {
    color: t("color-text");
  }

  &--color-secondary {
    @include themed() {
      color: t("color-text-secondary");
    }
  }

  &--table-header {
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
}
