@import "styles/themes.scss";

.max-width-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--infinity {
    max-width: none !important;
  }

  &--default {
    max-width: $max-limiter-default;
  }

  &--large {
    max-width: $max-limiter-large;
  }

  &--form {
    max-width: $max-limiter-form;
  }
}
