@import "styles/themes.scss";

.fixed-controllers-toolbar {
  border-radius: 12px;
  padding: 8px 12px;
  width: 100%;
  z-index: 10;
  overflow: hidden;

  @include themed() {
    background-color: t("color-surface");
    box-shadow: t("shadow");
  }

  &--right-side {
    position: absolute;
    right: 0;
    width: auto;
    display: flex;

    align-items: center;
    justify-content: center;
  }

  &--left-side {
    width: calc(100% - 310px); //TODO
  }

  &--left-side-large {
    width: calc(100% - 160px); //TODO
  }

  &--default {
    position: fixed;
    bottom: 30px;
    max-width: 700px;
  }

  &--delete-button {
    border-radius: 12px;
    cursor: pointer;

    @include themed() {
      border: 1px solid t("color-border-input");
    }

    &--icon {
      @include themed() {
        padding: 8px 10px;
        color: t("color-error");
      }
    }
  }

  &-select-dropdown {
    bottom: auto !important;
    top: 100% !important;
    width: 130px;
  }

  .toolbar-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
