@import "styles/themes.scss";
@import "styles/utils.scss";

.date-picker-container {
  display: flex;
  align-items: center;
  min-height: 37px;
  border-radius: 8px;

  @include themed() {
    background-color: t("color-surface");
    border: solid 1px t("color-border");
    box-shadow: t("shadow");
  }

  &--error {
    @include themed() {
      border: 1px solid t("color-error");
      box-shadow: 0 0 0 3px rgba(t("color-error"), 0.2);
    }
  }
}

.react-datepicker__input-container {
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
    height: 25px;
    border-radius: 5px;

    @include themed() {
      background: rgba(t("color-disabled"), 0.2);
    }
  }
}

.date-picker {
  border: none;
  cursor: pointer;

  &__select {
    border: 1px solid red;
  }
}
