@import "styles/themes.scss";

.text-editor-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: $main-border-radius;
  overflow: hidden;
  padding: 10px;
  gap: 10px;

  @include themed() {
    color: t("color-text-input");
    background: t("color-surface");
    border: 1px solid t("color-border");
    box-shadow: t("shadow-text-input");
  }

  &:focus-within {
    @include themed() {
      border-color: t("color-primary");
      box-shadow: 0 0 0 3px rgba(t("color-primary"), 0.2);
    }
    
  }

  &-panel {
    height: 36px;
  }

  &-toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 37px;
    padding: 2px;
    border-radius: $main-border-radius;

    @include themed() {
      background-color: t("color-surface-dark");
    }
  }

  &-button {
    height: 37px;
    padding: 5px;
    min-width: 84px;
  }

  &-content {
    overflow-y: auto;
  }
}

.ProseMirror {
  border: none;
  outline: none;
  overflow-y: auto;
  font-size: $font-size-running-text;
  font-weight: $font-weight-medium;

  > p {
    padding: 0px;
    margin: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: $font-size-large-text;
    font-size: $font-size-small-text;
    font-weight: $font-weight-medium;
    @include themed() {
      color: t("color-text");
    }
  }

  ul {
    margin: 0px;
    padding-left: 20px;
    line-height: 1;

    li {
      margin: 0px;
      padding: 0px;
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  strong {
    font-weight: bold !important;
  }

  h1 {
    font-weight: bold;
    font-size: 32px;
  }

  h2 {
    font-weight: bold;
    font-size: 26px;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  &:active {
    border: none;
    outline: none;
  }

  &-focus {
    border: none;
    outline: none;
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

iframe {
  border: none;
  width: 100%;
}
