@import "styles/themes.scss";

.select-dropdown-list {
  position: relative;
  display: flex;
  align-items: center;

  &-buttons-wrapper {
    border-radius: $main-border-radius;
    display: flex;
    gap: 5px;
    align-items: center;

    @include themed() {
      background-color: t("color-light");
    }
  }

  &-button {
    border-radius: $main-border-radius;
    padding: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &-opened {
      @include themed() {
        background: t("color-secondary-light");
      }
    }
  }

  &-menu {
    list-style: none;
    z-index: 1000 !important;

    &-wrapper {
      z-index: 100 !important;
      position: absolute;

      padding: 8px;
      border-radius: $main-border-radius;

      @include themed() {
        box-shadow: t("shadow");
        background: t("color-background");
      }

      top: 45px;

      &.left-sided {
        right: 0;
      }
  
      &.right-sided {
        left: 0;
      }
    }

    &-item {
      z-index: 1000 !important;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
      padding: 8px;
      margin: 2px 0;
      transition: background-color 0.2s linear;
      border-radius: $main-border-radius;
      min-width: 180px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include themed() {
        background-color: t("color-background");
      }

      &:hover {
        @include themed() {
          background-color: t("color-light");
        }
      }

      &-selected {
        @include themed() {
          background-color: t("color-light");
        }
      }
    }
  }
}