@import "styles/themes.scss";
@import "styles/utils.scss";

.default-text-area-container {
  position: relative;
  width: 100%;
  min-width: 240px;
  height: auto;
  padding: 0;

  .default-text-area-field {
    width: 100%;
    height: 100%;
    min-height: 58px;
    box-sizing: border-box;
    font-size: $font-size-running-text;
    font-weight: $font-weight-regular;
    border-radius: 6px;
    padding: 18px 8px 8px 8px;
    overflow-y: auto;

    @include themed() {
      color: t("color-text-input");
      background-color: t("color-grey-20");
      border: 1px solid t("color-grey-60");
    }

    &:hover {
      cursor: pointer;

      @include themed() {
        border: 1px solid t("color-hover-grey");
      }
    }

    &:focus {
      outline: none;

      @include themed() {
        border: 1px solid t("color-text-secondary-black");
        opacity: 0.85;
        box-shadow: none;
      }      
    }

    &:focus ~ .default-text-area-label {
      transform: translateY(40%);
      font-size: 10px;
      left: 9px;
    }

    &--readonly {
      cursor: default !important;

      @include themed() {
        color: t("color-text-primary") !important;
        border-color: t("color-grey-1-inactive") !important;
        background-color: t("color-grey-3-inactive") !important;
      }
    }

    &--error, &--error:hover, &--error:focus {
      @include themed() {
        border-color: t("color-accent-red");
      }
    }

    &--disabled {
      cursor: not-allowed !important;
      
      @include themed() {
        color: t("color-text-primary-inactive") !important;
        border-color: t("color-grey-1-inactive") !important;
        background-color: t("color-grey-3-inactive") !important;
      }
    }
  }

  .default-text-area-label {
    position: absolute;
    font-size: $font-size-running-text;
    font-weight: $font-weight-regular;
    left: 8px;
    transform: translateY(100%);
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    opacity: 0.5;

    @include themed() {
      color: t("color-text-primary");
    }

    &--disabled {
      opacity: 1 !important;

      &:hover {
        cursor: not-allowed !important;
      }

      @include themed() {
        color: t("color-text-inactive");
      }
    }

    &--readonly {
      opacity: 1 !important;

      &:hover {
        cursor: default !important;
      }

      @include themed() {
        color: t("color-text-inactive");
      }
    }

    &--on-top {
      transform: translateY(40%);
      font-size: 10px;
      left: 9px;
    }

    &--error {
      @include themed() {
        color: t("color-accent-red");
        opacity: 1;
      }
    }

    .text-area-required-field {
      opacity: 1 !important;

      @include themed() {
        color: t("color-brand-primary");
      }

      &--error {
        opacity: 1;
        
        @include themed() {
          color: t("color-accent-red");
          opacity: 1;
        }
      }

      &--disabled {
        opacity: 1 !important;
  
        @include themed() {
          color: t("color-brand-green-inactive") !important;
        }
      }

      &--readonly {
        opacity: 1 !important;
  
        @include themed() {
          color: t("color-brand-green-inactive") !important;
        }
      }
    }
  }
}
