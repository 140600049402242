@import "styles/themes.scss";

.component-container-wrapper {
  .component-container {
    padding: 30px;
    border-radius: 20px;

    @include themed() {
      background-color: t("color-surface");
      box-shadow: t("shadow");
    }

    &--auto-width {
      width: auto;
    }

    &--no-padding {
      padding: 0;
    }

    &--headline-padding {
      padding: 15px 30px 0px 30px;
    }

    &--no-border-radius {
      border-radius: 5px;
    }

    .component-wrapper-container {
      display: flex;
      flex-direction: column;
      position: relative;

      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0px 5px;
        padding-bottom: 10px;
        margin-bottom: 15px;

        @include themed() {
          border-bottom: 0.5px solid t("color-text");
        }
      }

      &-content {
        box-sizing: border-box;

        &--disabled {
          cursor: pointer;
          pointer-events: none !important;
          opacity: 0.7 !important;
        }
      }

      &--no-padding {
        .component-wrapper-container-content {
          padding: 0;
        }
      }
    }
  }
}
