@import "styles/themes.scss";

.role-type-list {
  position: relative;
  height: 100%;
  width: 60%;
  min-width: 720px;
  margin: 40px 0;
  padding: 20px;
  border-radius: 14px;
  overflow: hidden;

  @include themed() {
    background-color: t("color-surface");
  }

  &-header {
    padding-bottom: 10px;

    @include themed() {
      border-bottom: 1px solid t("color-border");
    }
  }
}