.color-input-container .default-input-field {
  height: 40px;
  width: 40px;
  padding: 1px;
  cursor: pointer;
}

.color-input-circle {
  height: 26px;
  width: 26px;
  border-radius: 22%;
}
