@import "styles/themes.scss";

.default-modal-content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  padding-top: 10px;
  flex-grow: 1;
  overflow-y: auto;
}
