@import "styles/themes.scss";
@import "styles/utils.scss";

.studio-overview-screen {
  position: relative;
  overflow: hidden;

  .logo-top-left {
    position: absolute;
    left: -10%;
    top: -10%;
    opacity: 0.5;
    max-width: 420px;

    @include for-phone-only {
      max-width: 220px;
    }
  }

  .logo-bottom-right {
    position: absolute;
    right: -8%;
    bottom: -5%;
    opacity: 0.5;
    max-width: 420px;

    @include for-phone-only {
      max-width: 220px;
    }
  }

  .studio-overview {
    position: absolute;
    overflow: scroll;
    padding-bottom: 20px;

    &-content {
      max-width: 704px;

      .organization-section {
        .component-container {
          padding: 15px 29px 29px;

          @include themed() {
            background-color: t("color-grey-20");
          }
        }
      }

      .add-organization-button {
        width: 212px;
        height: 40px;
        padding: 0 20px;

        @include themed() {
          color: t("color-surface");
          background-color: t("color-brand-primary");
        }
      }
    }

    @include for-phone-only {
      padding: 20px;
    }
  }

  .orga-setting-item {
    width: 32px;
    height: 32px;

    @include themed() {
      background-color: t("color-background");
    }

    &:hover {
      .orga-setting-item-icon {
        @include themed() {
          color: t("color-primary");
        }
      }
    }
  }
}
