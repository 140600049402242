@import "styles/themes.scss";
@import "styles/utils.scss";

.attachment-container {
  object-fit: contain;
  width: auto;
  height: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: $main-border-radius;
  font-size: $font-size-small-text;
  padding: 0 10px;
  cursor: pointer;

  @include themed() {
    background-color: t("color-surface-dark");
    color: t("color-inverted");
    border: 1.5px solid t("color-border");
  }

  &:hover {
    @include themed() {
      color: t("color-surface");
      background-color: t("color-secondary-highlight");
      transition: background-color 0.2s linear;
    }
  }

  &-icon {
    margin-right: 10px;
  }
}
