.gymo-icon {
  object-fit: contain;
  width: auto;

  rect {
    fill: #fff;
  }

  &--lettering {
    padding-right: 10px !important;
  }
}

.gymo-title {
  &--lettering {
    padding-left: 0 !important;
  }
}
