@import "styles/themes.scss";

.task-modal-wrapper {
  height: 74vh;

  &-header {
    border: none !important;
  }

  &--no-headline {
    padding-top: 40px;
  }

  .link-tabs-component-wrapper {
    height: auto;
  }

  .task-modal-form-wrapper {
    .main-form-container {
      padding: 0;

      .task-info-container {
        margin: 0;
        position: relative;
        height: 59vh;

        .task-cards-container {
          position: relative;
          height: 100%;
          overflow-y: auto; 
        }

        .attachments-list {
          min-height: 14vh;
          max-height: 20vh;
          overflow-y: auto;
          font-size: $font-size-running-text;
        } 

        .upload-container {
          position: relative;
          width: 100%;
          min-height: 40vh;
          padding: 0;
          aspect-ratio: 1;
        
          .file-upload-list-placeholder {
            &-icon {
              height: 30px;
            }
        
            &-text {
              font-size: $font-size-title-text;
              width: 40%;
              text-align: center;
            }
          }
        }        
      }

      .comment-card-container {
        position: relative;
        width: 70%;
        padding: 5px;
        border-radius: $main-border-radius;
    
        @include themed() {
          border: solid 2px t("color-border");
          box-shadow: t("shadow");
        }

        &--description {
          width: 100%;
          border: none;

          @include themed() {
            background-color: rgba(t("color-warning"), 0.2);
          }            
        }
    
        &-header {
          width: 100%;
        }

        &-image {
          width: 25px;
          height: 25px;
          border-radius: 5px;
        }

        &-email {
          font-size: $font-size-small-text;
          font-weight: $font-weight-bold;
        }
      
        &-data {
          height: 100%;
          width: auto;
          margin-left: 45px;
        }
    
        &-icon {
          width: auto;
          cursor: pointer;

          .simple-dropdown-button {
            &:hover {
              @include themed() {
                background-color: t("color-hover");
              }
            }
          }

          .simple-dropdown-item {
            position: relative  ;
            z-index: 9999;
            min-width: 100px;
          }
        }
    
        .task-card-text {
          width: 80%;
        }
      
        .task-card-date {
          font-size: 10px;
          font-weight: $font-weight-regular;
          line-height: 13px;

          @include themed() {
            color: t("color-secondary-highlight");
          }
        }
      }

      .current-attachment-container {
        width: 480px;
        max-height: 100px;
        min-height: 40px;
        overflow-x: auto;
        margin-bottom: -10px;
      }
      
      .task-info-editor {
        max-height: 250px;
        min-height: 150px;

        &--editable-description {
          height: 100%;

          @include themed() {
            background-color: rgba(t("color-warning"), 0.2);
          }
        }

        &--editable-comment {
          height: 100%;

          @include themed() {
            background-color: t("color-background");
          }
        }
      }
    }

    .detail-form-container{
      height: 56vh;
      padding: 20px;
      border-radius: $main-border-radius;

      @include themed() {
        background-color: t("color-background");
        border: solid 2px t("color-border");
        box-shadow: t("shadow");
      }

      .task-detail-form-wrapper {
        padding-right: 10px;
        padding-top: 5px;
        max-height: 47vh;
        overflow-y: auto;

        .task-detail-name {
          font-size: $font-size-running-text;
          font-weight: $font-weight-bold;
        }
      }

      .column-bottom-border {
        padding-bottom: 5px;

        @include themed() {
          border-bottom: solid 1px t("color-border");
        }
      }

      .drop-down-select {
        width: 50%;

        :hover {
          cursor: pointer;
          border-radius: 5px;

          @include themed() {
            background: rgba(t("color-disabled"), 0.2);
          }

          .dropdown-selection {
            &__control {
              @include themed() {
                color: t("color-secondary");
              }
            }

            &__dropdown-indicator {
              @include themed() {
                color: t("color-secondary");
              }
            }
          }
        }

        &--error {
          width: 50%;
          border-radius: 8px !important;

          @include themed() {
            border: 1px solid t("color-error");
            box-shadow: 0 0 0 3px rgba(t("color-error"), 0.2);
          }
        }

        &--disabled {
          :hover {
            cursor: default;
            border: 2px solid red;
  
            @include themed() {
              background: t("color-surface");
            }
          }
        }

        .dropdown-selection {
          &__control {
            @include themed() {
              border: none !important;
            }

            :hover {
              background: transparent;
            }
          }

          &__indicator-separator {
            opacity: 0;
          }

          &__clear-indicator {
            display: none;
          }

          &__multi-value {
            @include themed() {
              background-color: t("color-background");
            }

            &__label {
              padding: 0;
            }
          }
        }
      }

      .text-input-field {
        width: 50%;

        :hover {
          cursor: pointer;
          border-radius: 5px;

          @include themed() {
            background: rgba(t("color-disabled"), 0.2);
          }
        }

        .default-input-field {
          border: none !important;
          box-shadow: none;
        }
        
        &--error {
          width: 50%;
          border-radius: 8px !important;

          @include themed() {
            border: 1px solid t("color-error");
            box-shadow: 0 0 0 3px rgba(t("color-error"), 0.2);
          }
        }
      }

      .form-input {
        margin: 0;
        width: 50%;

        .default-input-field {
          border: none;
          box-shadow: none;
          cursor: default;
        }
      }

      .action-button {
        width: 45%;

        @include themed() {
          color: t("color-secondary");
          background-color: t("color-disabled");
        }

        &:hover {
          @include themed() {
            background-color: t("color-hover");
          }
        }
      }
    }
  }
}

.text-editor-container-plain-text {
  position: relative; 
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  width: 80%;
  padding: 0 !important;
  border: none !important;  
  box-shadow: none !important;

  @include themed() {
    background-color: rgba(t("color-surface"), 0.1);
  }

  .text-editor-container-content {
    padding: 0;
  }
}

.date-picker-container {
  z-index: 999;
  width: 50%;
  border: none !important;
  box-shadow: none !important;

  .react-datepicker__input-container {
    width: 120% !important;
    
    :hover, :focus {
      width: 120% !important;

      @include themed() {
        background: rgba(t("color-disabled"), 0.4);
      }
    }

    :disabled {
      cursor: not-allowed;
    }

    >svg {
      display: none;
    }
  }

  .react-datepicker__view-calendar-icon {
    >input {
      padding-left: 10px;
    }
  }

  .react-datepicker-popper {
    left: 20px !important;
  }
}

.comment-attachment-container {
  width: 100%;
  height: auto;
  padding-top: 5px;
  display: inline-block;
  margin-right: 10px;

  @include themed() {
    border-top: solid 2px t("color-border");
  }
}

.list-data-table-body-item {
  cursor: auto;

  &:not(.list-data-table-body-item-selected):hover {
    @include themed() {
      background-color: t("color-form-wrapper");
    }
  }
}

.task-toolbar {
  border-radius: 12px;
  padding: 8px 12px;
  width: 100%;

  @include themed() {
    background-color: t("color-surface");
    box-shadow: t("shadow");
  }

  
  &--delete-button {
    border-radius: $main-border-radius;
    cursor: pointer;

    @include themed() {
      background-color: t("color-error");
    }

    &--icon {
      @include themed() {
        padding: 8px 10px;
        color: t("color-surface");
      }
    }
  }

  &--complete-button {
    width: 240px;
  }

  &--save-button {
    width: 100px;
  }
}
