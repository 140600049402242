@import "styles/themes.scss";

.bold-text {
  font-weight: bold;
}

.status-icon {
  margin-right: 7px;

  @include themed() {
    color: t("color-primary");
  }
}
