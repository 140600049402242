.gradient-canvas {
  width: 100%;
  height: 100vh;
  display: block;
  --gradient-color-1: #c3e4ff;
  --gradient-color-2: #ffffff;
  --gradient-color-3: #eaecfe;
  --gradient-color-4: #f9def7;
}

.theme--dark {
  .gradient-canvas {
    --gradient-color-1: #0e2343;
    --gradient-color-2: #1a1a1a;
    --gradient-color-3: #202024;
    --gradient-color-4: #350223;
  }
}
