@import "styles/themes.scss";

.app-preview-container {
  position: relative;
  flex: 1;
  border-radius: 40px;
  padding: 5px;
  height: 100%;
  max-width: 350px;
  max-height: 700px;
  aspect-ratio: 9 / 19.5;
  cursor: pointer;

  @include themed() {
    box-shadow: t("shadow");
    background-color: t("color-border");
  }

  &::after {
    content: "";
    top: 20px;
    position: absolute;
    height: 22px;
    width: 35%;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 40px;

    @include themed() {
      background-color: t("color-background");
    }
  }

  .app-preview-iframe {
    height: 100%;
    width: 100%;
    border-radius: 36px;
    border: none;
  }
}
