$themes: (
  light: (
    // Color themes
    color-primary: rgb(0, 168, 109),
    color-primary-highlight: #00a86d,
    color-primary-ultralight: #e6f9f4,
    color-secondary: #2a2a2a,
    color-form-wrapper: #f9f9f9,
    color-secondary-highlight: #5c5c5c,
    color-background: #ffffff,
    color-inverted: #000000,
    color-surface: white,
    color-surface-dark: #f2f2f2,
    color-border: #dddddd,
    color-error: #fd5d5d,
    color-success: rgb(92, 195, 122),
    color-hover: hsla(158, 66%, 51%, 0.1),
    color-warning: #f8b400,
    color-disabled: #cacecd,
    color-text-input: rgb(28, 32, 37),
    color-light: #EFEFEF,
    color-secondary-light: #D4EDE4,
    color-greenish-grey: #404543,
    color-hover-grey: #525252,
    color-hover-green: #D4EDE4,
    color-grey-80: #949494,
    color-grey-60: #DBDBDB,
    color-grey-40: #EFEFEF,
    color-grey-20: #F9F9F9,
    color-grey-10: #F8FAFA,
    color-grey-1-inactive: #F1F1F1,
    color-grey-3-inactive: #FDFDFD,
    color-accent-red: #F76056,
    color-alarm-red: #C61212,
    color-accent-red-20: rgba(#F76056, 0.2),
    
    // Text themes
    color-text: #313634,
    color-text-secondary: #000000,
    color-text-primary: #191919,
    color-text-inactive: #D0D0D0,
    color-text-primary-inactive: #A3A3A3,
    color-brand-primary: #00A86D,
    color-brand-green-inactive: #99DCC5,
    color-text-secondary-black: rgb(25, 25, 25),

    // Shadow themes
    shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1),
    shadow-text-input: rgba(0, 0, 0, 0.05) 0px 2px 4px,
    // Component themes
    color-navigation-bar: transparent
      linear-gradient(270deg, #222222 0%, #080808 100%) 0% 0% no-repeat
      padding-box,
  ),
  dark: (
    // Color themes
    color-primary: rgb(0, 168, 109),
    color-primary-highlight: #00a86d,
    color-primary-ultralight: #e6f9f4,
    color-secondary: #000000,
    color-form-wrapper: #f9f9f9,
    color-secondary-highlight: #5c5c5c,
    color-surface-dark: #f2f2f2,
    color-background: #000000,
    color-inverted: #ffffff,
    color-surface: #212322,
    color-border: #707070,
    color-error: #fd5d5d,
    color-success: #5cc37a,
    color-warning: #f8b400,
    color-hover: #292c2d,
    color-disabled: #cacecd,
    color-text-input: rgb(28, 32, 37),
    color-light: #EFEFEF,
    color-secondary-light: #D4EDE4,
    // Text themes
    color-text: #ebe5e5,

    // Shadow themes
    shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3),
    shadow-text-input: rgba(0, 0, 0, 0.05) 0px 2px 4px,
    // Component themes
    color-navigation-bar: transparent
      linear-gradient(270deg, #222222 0%, #080808 100%) 0% 0% no-repeat
      padding-box,
  ),
);

// Font sizes
$font-size-large-headline: 34px;
$font-size-default-headline: 24px;
$font-size-small-headline: 18px;

$font-size-large-text: 16px;
$font-size-title-text: 15px;
$font-size-running-text: 13px;
$font-size-small-text: 12px;

// Heights
$mobile-nav-height: 60px;

// border radius
$main-border-radius: 10px;

// Font weights
// (when changing, also change the @font-face in src/index.scss)
$font-weight-bold: 600;
$font-weight-semi-bold: 500;
$font-weight-medium: 400;
$font-weight-regular: 300;
$font-weight-light: 200;

// Shadows
// ...

// Paddings
$modal-page-padding: 45px;
$default-page-padding: 20px;
$padding-page-content-left-right: 30px;
$padding-page-content-left-right-mobile: 20px;
$padding-page-content: 30px $padding-page-content-left-right 30px
  $padding-page-content-left-right;
$page-container-padding: 12px;

// Margins
// ...

// Widths

$max-limiter-large: 1400px;
$max-limiter-default: 1350px;
$max-limiter-form: 860px;

$max-xxl: 1250px;
$max-xl: 750px;
$max-xml: 500px;
$max-l: 400px;
$max-m: 300px;
$max-s: 200px;

// Utils for theme mode switcher
@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@mixin themedbody() {
  @each $theme, $map in $themes {
    .theme--#{$theme} {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
