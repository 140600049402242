@import "styles/themes.scss";

.table-image {
  border-radius: $main-border-radius;
  object-fit: cover;

  @include themed() {
    box-shadow: t("shadow");
  }
}

.table-image-empty {
  width: 100px;
  height: 100px;
  border-radius: $main-border-radius;

  @include themed() {
    border: 3px dotted t("color-text");
  }
}

.rounded-image {
  border-radius: 50%;
}

.table-image-small {
  height: 40px;
  width: 40px;
}

.table-image-medium {
  height: 52px;
  width: 52px;
}

.table-image-large {
  height: 75px;
  width: 75px;
}

.table-image-xl {
  height: 90px;
  width: 90px;
}

.table-image-xxl {
  height: 130px;
  width: 130px;
}

.table-image-full-size {
  height: 100%;
  width: 100%;
}
