@import "styles/themes.scss";

.highlighted-info-text {
  border-style: border-box;
  padding: 7px;
  border-radius: 5px;
  display: inline-block;
  min-width: 60px;
  text-align: center;

  &-small {
    padding: 3px;
  }

  &-fit-content {
    min-width: fit-content;
    width: fit-content;
    padding: 5px 8px;
  }

  &-success {
    @include themed() {
      background-color: #e6f8eb;

      .highlighted-info-text-pargraph {
        color: t("color-text");
      }
    }
  }

  &-warning {
    @include themed() {
      background-color: #f8f4e6;

      .highlighted-info-text-pargraph {
        color: t("color-text");
      }
    }
  }

  &-error {
    @include themed() {
      background-color: #f8e6e6;

      .highlighted-info-text-pargraph {
        color: t("color-text");
      }
    }
  }
}
