@import "styles/themes.scss";

.kanban-board-wrapper {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  .react-kanban-board {
    height: calc(100% - 10px);
    overflow-y: auto !important;

    > div {
      height: 100%;
    }

    .react-kanban-column {
      border-radius: $main-border-radius;
      position: relative;
      height: calc(100% - 40px) !important;
      padding-right: 5px;

      > div {
        &:first-child {
          padding-right: 15px;
          padding-bottom: 10px;
          font-weight: 600;
        }

        &:last-child {
          overflow-y: auto;
          padding-right: 10px;

          > div {
            margin-bottom: 15px;
          }
        }
      }

      .kanban-column-header-container {
        font-weight: 600;
      }

      .kanban-card-container {
        width: 250px;
      }

      > div {
        &:last-of-type {
          > div {
            &:last-of-type {
              .kanban-card-container {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
