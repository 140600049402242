@import "styles/themes.scss";

.big-market-place-add-on {
  border-radius: 10px;
  overflow: hidden;
  min-width: 200px;
  max-width: 270px;

  @include themed() {
    background-color: t("color-surface");
    box-shadow: t("shadow");
    border: 0.5px solid transparent;
  }

  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include themed() {
      background-color: t("color-surface");
    }

    .add-one-image {
      height: 100%;
      width: 100%;
      max-height: 200px;
      object-fit: cover;
    }

    .creator-image-wrapper {
      width: 35px;
      height: 35px;
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: #ffffff;
      border-radius: 10px;
      display: flex;

      &--padding {
        padding: 4px;
        width: calc(35px - 8px);
        height: calc(35px - 8px);
      }

      .creator-image {
        border-radius: 10px;
        object-fit: fill;
        max-width: 100%;
        max-height: 100%;
        align-items: center;
        justify-self: center;
      }
    }

    .tags-wrapper {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }

  .add-on-content {
    padding: 15px;

    .price-label {
      @include themed() {
        color: t("color-primary");
      }
    }
  }

  &:hover {
    @include themed() {
      border: 0.5px solid t("color-primary");
      cursor: pointer;
    }
  }
}
