@import "styles/themes.scss";
@import "styles/utils.scss";

.outlined-text-input-container {
  position: relative;
  width: 100%;
  min-width: 240px;
  height: 44px;

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button !important;
    opacity: 1 !important;
    height: 20px !important;
    position: absolute !important;
    top: 12px !important;
    right: 10px !important;
  }

  .default-input-field {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-size: $font-size-running-text;
    font-weight: $font-weight-regular;
    border-radius: 6px;
    padding: 18px 8px 4px;

    @include themed() {
      color: t("color-text-input");
      background: t("color-grey-20") !important;
      border: 1px solid t("color-grey-60");
    }

    @include for-phone-only {
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;

      @include themed() {
        border: 1px solid t("color-hover-grey");
      }
    }

    &:focus {
      outline: none;

      @include themed() {
        border: 1px solid t("color-text-secondary-black");
        opacity: 0.85;
        box-shadow: none;
      }      
    }

    &:focus ~ .default-input-label {
      transform: translateY(50%);
      font-size: 10px;
      left: 9px;
    }

    &--readonly {
      cursor: default !important;

      @include themed() {
        color: t("color-text-primary") !important;
        border-color: t("color-grey-1-inactive") !important;
        background-color: t("color-grey-3-inactive") !important;
      }
    }

    &--error, &--error:hover, &--error:focus {
      @include themed() {
        border-color: t("color-accent-red");
      }
    }

    &--disabled {
      cursor: not-allowed !important;
      
      @include themed() {
        color: t("color-text-primary-inactive") !important;
        border-color: t("color-grey-1-inactive") !important;
        background-color: t("color-grey-3-inactive") !important;
      }
    }

    &--secondary {
      &:focus {
        @include themed() {
          border-color: #525252;
          box-shadow: none;
        }
      }
    }
  }

  .default-input-label {
    position: absolute;
    font-size: $font-size-running-text;
    font-weight: $font-weight-regular;
    left: 8px;
    transform: translateY(100%);
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    opacity: 0.5;

    @include themed() {
      color: t("color-text-primary");
    }

    &--disabled {
      opacity: 1 !important;

      &:hover {
        cursor: not-allowed !important;
      }

      @include themed() {
        color: t("color-text-inactive");
      }
    }

    &--readonly {
      opacity: 1 !important;

      &:hover {
        cursor: default !important;
      }

      @include themed() {
        color: t("color-text-inactive");
      }
    }

    &--on-top {
      transform: translateY(50%) !important;
      font-size: 10px !important;
      left: 9px !important;
    }

    &--error {
      @include themed() {
        color: t("color-accent-red");
        opacity: 1;
      }
    }

    .required-field {
      opacity: 1 !important;

      @include themed() {
        color: t("color-brand-primary");
      }

      &--error {
        opacity: 1;

        @include themed() {
          color: t("color-accent-red");
        }
      }

      &--disabled {
        opacity: 1 !important;
  
        @include themed() {
          color: t("color-brand-green-inactive") !important;
        }
      }

      &--readonly {
        opacity: 1 !important;
  
        @include themed() {
          color: t("color-brand-green-inactive") !important;
        }
      }
    }
  }

  .suffix-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-60%);
    color: gray;
    cursor: pointer;

    @include themed() {
      color: t("color-text");
    }

    &--secondary {
      right: -15px;
      width: 12px;
      height: 12px;

      @include themed() {
        color: t("color-text-inactive");
        opacity: 0.5;
      }
    }
  }
}
