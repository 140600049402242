@import "styles/themes.scss";
@import "styles/utils.scss";

.default-search-input-container {
  position: relative;
  width: 320px;
  min-width: 240px;
  height: 36px;

  .default-search-input-field {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-size: $font-size-small-text;
    font-weight: $font-weight-light;
    border-radius: 4px;
    padding: 10px 8px 10px 32px;
    font-family: "Poppins";

    @include themed() {
      color: t("color-text-input");
      background: t("color-background");
      border: 1px solid t("color-grey-60");
    }

    &:hover {
      cursor: pointer;

      @include themed() {
        border: 1px solid t("color-hover-grey");
      }
    }

    &:focus {
      outline: none;

      @include themed() {
        border: 1px solid t("color-text-secondary-black");
        opacity: 0.85;
        box-shadow: none;
      }      
    }
  }

  .search-input-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 8px;
    top: 10px;
    
    @include themed() {
      color: t("color-text-inactive");
    }
  }

  .close-input-icon {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 12px;
    top: 13px;
    
    @include themed() {
      color: t("color-text-inactive");
      opacity: 0.5;
    }

    &:hover {
      cursor: pointer;

      @include themed() {
        color: t("color-hover-grey");
      }
    }
  }
}
