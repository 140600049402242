@import "styles/themes.scss";
@import "styles/utils.scss";

.link-tabs-component-wrapper {
  width: 100%;
  height: 40px;
  box-sizing: border-box;

  &--border {
    @include themed() {
      border-bottom: 1px solid t("color-border");
    }
  }

  .link-tabs-list-wrapper {
    height: auto;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include for-phone-only {
      margin-right: 20px;
    }

    .link-tabs-list-container {
      gap: 20px;
      display: flex;

      &-actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
