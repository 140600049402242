@import "styles/themes.scss";

.card-wrapper {
  width: 210px;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 12px;
  border-radius: $main-border-radius;
  cursor: pointer;

  @include themed() {
    background: t("color-background");
  }

  &-image {
    height: 40px !important;
    width: 40px !important;
  }

  .title-text {
    font-size: $font-size-small-text;
    font-weight: $font-weight-semi-bold;
  }

  .value-text {
    font-size: 10px;
    font-weight: $font-weight-light;
  }

  &:hover {
    @include themed() {
      border-color: t("color-primary");
      box-shadow: 0 0 0 3px rgba(t("color-primary"), 0.2);
    }

    .title-text {
      @include themed() {
        color: t("color-primary");
      }
    }

    .value-text {
      @include themed() {
        color: t("color-primary");
      }
    }
  }
}
