@import "styles/themes.scss";

.bubble-menu {
  display: flex;
  position: relative;
  align-items: center;

  &-item {
    border: none;
    background: none;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;

    @include themed() {
      color: t("color-inverted");
    }

    .toolbar-icon {
      font-size: 14px;
    }

    &:hover,
    &.is-active {
      @include themed() {
        color: t("color-primary");
      }
    }

    &.is-active > svg > path:first-of-type {
      @include themed() {
        fill: t("color-primary");
      }
    }

    &-icon {
      width: auto;

      @include themed() {
        color: t("color-primary");
        filter: t("image-filter-rev");
      }
    }
  }

  .spacing {
    opacity: 1;
  }

  &-link-url-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;

    @include themed() {
      background-color: t("color-background");
    }

    .toolbar-icon {
      cursor: pointer;
    }

    .link-input {
      border: none;
      outline: none;
      width: 100%;
      height: 100%;

      @include themed() {
        color: t("color-inverted");
      }
    }
  }
}
