@import "styles/themes.scss";
@import "styles/utils.scss";

.data-table-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 22px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 0 0 12px 12px;
  padding: 0 10px 0 10px;

  @include themed() {
    background-color: t("color-grey-60");
  }

  span {
    font-size: $font-size-small-text;
    color: rgba(0, 0, 0, 0.5);
  }

  &--select {
    height: 36px;
    line-height: 36px;
    justify-content: space-between;

    .page-select-container {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: $font-size-small-text;
     
      @include themed() {
        color: t("color-text-secondary");
      }
  
      .page-select-section {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 180px;
        margin-right: 20px;
  
        .page-select {
          .css-13cymwt-control, .css-t3ipsp-control {
            min-height: 24px !important;
          }

          .page-select-dropdown {
            &__control {
              position: relative;
              top: -1px !important;
              display: flex !important;
              justify-content: center !important;
              align-items: center !important;
              width: 64px !important;
              height: 24px !important;
              border-radius: 4px !important;
              padding-left: 8px;
              padding-right: 6px;
              font-family: "Poppins" !important;
              font-size: $font-size-running-text !important;
              font-weight: $font-weight-medium !important;
          
              @include themed() {
                color: t("color-text-input") !important;
                background: t("color-background") !important;
              }
          
              &:hover {
                cursor: pointer;
          
                @include themed() {
                  background: t("color-grey-20") !important;
                }
              }
              &--menu-is-open {
                @include themed() {
                  background: t("color-hover-green") !important;
                }
              }
            }
          
            &__value-container {
              height: 24px !important;
              padding: 0 6px !important;
            }

            &__single-value {
              display: flex;
              align-items: center;
            }
          
            &__input-container {
              height: 24px !important;
              padding: 0;
              margin: 0;
              width: 34px !important;
            }
          
            &__indicator-separator {
              display: none !important;
            }
          
            &__clear-indicator {
              display: none !important;
            }
          
            &__indicator {
              width: 12px !important;
              height: 12px !important;
              background-image: url("/assets/icons/chevron_down.svg");
              background-repeat: no-repeat;
              padding: 0 !important;
          
              @include themed() {
                color: t("color-text-inactive") !important;
              }
          
              svg {
                display: none !important  ;
                width: 12px !important;
                height: 12px !important;
              }
            }
          }
        }
      }
  
      .page-navigate-section {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 140px;
        padding: 6px 16px;
        box-sizing: border-box;
  
        &-button {
          height: 18px !important;
        }
  
        .current-page-number {
          @include themed() {
            color: t("color-text-secondary");
          }
        }
      }
    }
  }
}

// dropdown icon rotation when open/close menu
.page-select-dropdown__control--menu-is-open {
  .page-select-dropdown__indicators {
    .page-select-dropdown__dropdown-indicator {
      transform: rotate(180deg) !important;
    }
  }
}

.page-select-dropdown__menu {
  margin-top: 0 !important;
  width: 64px !important;
  padding: 8px !important;
  box-shadow: 3px 6px 20px 0px rgba(0, 0, 0, 0.08);
}

.page-select-dropdown__option {
  width: 100% !important;
  height: 32px !important;
  padding: 8px !important;
  border-radius: 3px !important;
  margin-bottom: 4px !important;
  font-family: "Poppins" !important;
  font-size: $font-size-running-text !important;
  font-weight: $font-weight-light !important;
  line-height: 130% !important;

  @include themed() {
    background: t("color-background") !important;
    color: t("color-text-inactive") !important;
  }

  &:hover, &--is-selected {
    cursor: pointer;
    
    @include themed() {
      background: t("color-grey-40") !important;
    }
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}
