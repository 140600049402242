@import "styles/themes.scss";

.image-placeholder {
  height: 40px !important;
  min-width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $main-border-radius;
  font-size: 25px;

  @include themed() {
    background-color: t("color-secondary");
    color: t("color-surface");
  }
}
