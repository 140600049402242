@import "styles/themes.scss";
@import "styles/utils.scss";

.rc-slider-track {
  @include themed() {
    background-color: t("color-primary");
  }
}

.rc-slider-handle {
  @include themed() {
    border: solid 2px t("color-primary");
  }

  &-dragging {
    @include themed() {
      border-color: t("color-primary") !important;
      box-shadow: 0 0 0 5px t("color-primary-highlight") !important;
    }
  }
}
