@import "styles/themes.scss";

.user-role-page-container {
  @include themed() {
    background-color: t("color-grey-20");
  }
  
  .user-role-tabs {
    height: 100%;
    margin: 0;
  
    @include themed() {
      border-bottom: 1px solid t("color-border");
    }
  }
}