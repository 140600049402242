.push-notification-preview-container {
  position: relative;
  width: 100%;
  max-width: 375px;

  .phone-preview-image {
    width: 100%;
    object-fit: contain;
    border-radius: 12px;
    overflow: hidden;
  }

  .push-notification-container {
    position: absolute;
    padding: 10px;
    left: 30px;
    right: 30px;
    top: 72px;
    width: calc(100% - 60px);
    border-radius: 13.599px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);

    .app-name {
      color: rgba(0, 0, 0, 0.3);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.14px;
    }
  }
}
