@import "styles/themes.scss";

.form-wrapper {
  width: 100%;
  padding: 20px;
  border-radius: 10px;

  @include themed() {
    background-color: t("color-form-wrapper");
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px 5px;
    padding-bottom: 10px;
    margin-bottom: 15px;

    @include themed() {
      border-bottom: 1px solid t("color-text");
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
  }

  &--no-padding {
    padding: 0;
  }
}
